<template>
	<div>
		<b-container fluid class="shadow mb-5">
			<!-- <h5 class="text-center my-4" style="min-height: 1.2em">
				<a
					:href="urlsString.text"
					class="text-decoration-none text-muted"
					target="_blank"
				>
					{{ urlsString.text }}</a
				>
			</h5> -->

			<b-row class="mb-5">
				<b-col class="mb-5">
					<b-input-group class="mb=5" label="'Bad' Tags" label-for="badTags">
						<b-form-textarea
							class="mb-3"
							style="box-shadow: none"
							id="badTags"
							v-model="badTagsString"
							placeholder="Enter 'Bad' Tags (comma separated) ..."
							rows="1"
							max-rows="1"
							size="sm"
							squared
							trim
							description="text to analyze"
							title="Enter 'Bad' Tags (comma separated)"
							:state="badTags.length > 5"
							no-auto-shrink
						></b-form-textarea>
					</b-input-group>

					<b-input-group class="mb=5" label="Urls" label-for="urls">
						<b-form-textarea
							class="mb-3"
							style="box-shadow: none"
							id="urls"
							v-model="urlsString.text"
							placeholder="Enter URLs (New line separated) ..."
							rows="25"
							size="sm"
							squared
							trim
							description="URLs to analyze"
							title="Enter URLs (New line separated)"
							:state="urlsString.text.length > 10"
							no-auto-shrink
							autofocus
						></b-form-textarea>
					</b-input-group>

					<b-input-group>
						<!-- <b-form-input
							id="urls-string"
							style="box-shadow: none"
							v-model="urlsString.text"
							placeholder="Enter urls (new line separated) ..."
							size="sm"
							squared
							trim
							description="urlsString"
							autofocus
							type="text"
							@keydown.enter.native="runUrls()"
						></b-form-input> -->
						<b-form-input
							id="url-string-group"
							style="box-shadow: none"
							v-model="urlsString.group"
							placeholder="Enter urls group name ..."
							size="sm"
							squared
							trim
							description="urls group"
							type="text"
						></b-form-input>
						<b-button-group>
							<b-dropdown
								variant="dark"
								size="sm"
								squared
								:text="
									'location: ' +
									urlsString.location.name +
									' ' +
									'(' +
									urlsString.location.code +
									')'
								"
								id="urlsString-location"
								name="urlsString.location"
								v-model="urlsString.location"
							>
								<b-dropdown-item-button
									v-for="location in locations"
									:key="location.name"
									:value="location.country_iso_code"
									squared
									@click="urlsString.location = location"
								>
									{{
										location.name + " " + "(" + location.country_iso_code + ")"
									}}
								</b-dropdown-item-button>
							</b-dropdown>

							<b-dropdown
								variant="dark"
								size="sm"
								squared
								v-model="urlsString.language_code"
								:text="'language: ' + urlsString.language_code"
							>
								<b-dropdown-item-button
									v-for="language_code in languages"
									:key="language_code"
									:value="language_code"
									squared
									@click="urlsString.language_code = language_code"
								>
									{{ language_code }}
								</b-dropdown-item-button>
							</b-dropdown>

							<b-dropdown
								variant="dark"
								size="sm"
								squared
								v-model="urlsString.device"
								:text="'device: ' + urlsString.device"
							>
								<b-dropdown-item-button
									v-for="device in devices"
									:key="device"
									:value="device"
									squared
									@click="urlsString.device = device"
								>
									{{ device }}
								</b-dropdown-item-button>
							</b-dropdown>
						</b-button-group>
						<b-button
							:pressed="false"
							style="box-shadow: none"
							size="sm"
							squared
							variant="danger"
							@click="runUrls()"
							>Analyze</b-button
						>
					</b-input-group>
				</b-col>
			</b-row>

			<!-- spinner -->
			<div class="text-center mb-5" v-if="isBusy && spinner">
				<b-row type="grow" class="justify-content-md-center my-5 pb-5">
					<b-spinner
						variant="dark"
						style="width: 25rem; height: 25rem"
					></b-spinner>
				</b-row>
				<span class="sr-only">Analyzing URL: "{{ urlsString.text }}"</span>
			</div>
		</b-container>

		<b-container fluid v-if="!isBusy" class="shadow mt-5">
			<b-table
				id="concepts-table"
				:busy.sync="isBusy"
				:items="items"
				:fields="columns"
				ref="conceptsTable"
				stacked="md"
				show-empty
				small
				caption-top
			>
				<template #table-caption>Details</template>
			</b-table>
		</b-container>
	</div>
</template>

<script>
import { TaxonomyService } from "../services/taxonomy/taxonomy.service";

export default {
	name: "SinglePageUrl",
	components: {
		// FilterInput,
		// Chart,
	},
	data() {
		return {
			isBusy: true,
			spinner: false,

			badTags: [
				"header",
				"nav",
				"footer",
				"script",
				"noscript",
				"style",
				"video",
				"audio",
				"aside",
				"map",
				"optgroup",
				"object",
				"progress",
				"select",
				"svg",
				"figure",
				"iframe",
			],
			badTagsString: "",
			urlsString: {
				text: "https://www.galileo.tv/food/schoko-kuchen-aus-nur-drei-zutaten-rezept-back-tipps-schokolade/",
				language_code: "de",
				device: "mobile",
				// location: ["2276", "Germany", "", "DE", "Country"],
				location: {
					code: 2276,
					name: "Germany",
					code_parent: "",
					country_iso_code: "DE",
					type: "Country",
				},
				group: "",
				forceUpdate: false,
			},
			urls: [],
			languages: [
				"en",
				"de",
				"fa",
				"nl",
				"fr",
				"id",
				"it",
				// 'ja',
				"no",
				"pt",
				"ru",
				"es",
				"sv",
				"vi"
			],
			devices: ["desktop", "mobile"],
			locations: [],

			items: [],
			columns: [],
		};
	},

	computed: {},

	async beforeMount() {
		const locations = await TaxonomyService.getLocations();
		this.locations = locations.data;
		// this.badTagsString = this.badTags.toString();
		this.badTagsString = this.badTags.join(", ");
		// console.log(this.badTagsString);
	},

	methods: {
		async runUrls() {
			this.badTags = this.badTagsString.split(",").map((badTag) => {
				return badTag.trim();
			});

			// this.badTags = this.badTagsString.split(",").reduce((newArr, batTag) => {
			// 	if (batTag.article_id == item.article_id && batTag.tag == item.tag) {
			// 		console.log("batTag: ", batTag.add_tag);
			// 		console.log("item: ", item.add_tag);
			// 		batTag.add_tag = item.add_tag.toString();
			// 	}
			// 	newArr.push(batTag);
			// 	return newArr;
			// }, []);

			// if (this.urlsString.text) {
			this.isBusy = true;
			this.spinner = true;
			this.urls = this.urlsString.text.split(/\r?\n/).map((url) => {
				return url.trim();
			});
			console.log(this.urls);
			const { data } = await TaxonomyService.runUrls({
				urls: this.urls,
				// urlsString: this.urlsString.text.toLowerCase(),
				language_code: this.urlsString.language_code,
				location_code: this.urlsString.location.code.toString(),
				location_name: this.urlsString.location.name.toString(),
				device: this.urlsString.device,
				// serp_top_n: this.filterInput.value,
				forceUpdate: this.urlsString.forceUpdate,
				group: this.urlsString.group,
				badTags: this.badTags,
			});

			// const { data } = await TaxonomyService.runUrls({
			// 	urlsString: this.urlsString.text,
			// });

			if (data[0]) {
				delete data[0].body;
				this.items = data;
				this.columns = Object.keys(data[0]);
			}
			console.log("this.items: ", this.items);
			this.isBusy = false;
			this.spinner = false;

			return;
			// }
		},
	},
};
</script>

